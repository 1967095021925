








































































































































































































































































































import Vue from 'vue';
import moment from 'moment';
// @ts-ignore
import { sleep, message, baiduKey } from '~/util/common';
import AreaPopup from './AreaPopup.vue';
import AreaSelect from './AreaSelect.vue';
// @ts-ignore
import { result } from 'lodash';
import { find } from 'lodash';
import { cabinClassOption, defaultAirTicketSearch } from './helper.js';
// @ts-ignore
import BMap from 'vue-baidu-map/components/map/Map.vue';
// @ts-ignore
import * as domesticCity from './flightCode.json';

export default Vue.extend({
  name: 'SearchAirTicket',
  components: { AreaPopup, AreaSelect, BMap },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    searchInfo: {
      type: Object,
      default: () => JSON.parse(JSON.stringify(defaultAirTicketSearch)),
    },
    showPassengerType: {
      type: Boolean,
      default: true,
    },
    buttonVisible: {
      type: Boolean,
      default: true,
    },
  },
  data(): any {
    return {
      baiduKey,
      cabinClassOption,
      // cabinClass: '',
      pickerOptions: {
        disabledDate: (time: any) => {
          // 禁用未来10天内的日期 10 * 24 * 60 * 60 * 1000
          // return time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000;
          // 禁用今天之前的日期选择
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      passengerList: [],
      listQuery: {
        departCity: '',
        departCityInput: '',
        depCityCode: '',
        depDate: '',
        arriveCity: '',
        arriveCityInput: '',
        arrCityCode: '',
        arrDate: '',
        cabinClass: '',
        tripType: 1, // 1单程, 2往返, 5多程
        needMultiClassPrice: true, // 是否查询多仓位价格
        transferLeaveDate: '',
      },
      startInputActive: false,
      startInputStatus: 0,
      endInputActive: false,
      endInputStatus: 0,
      // startPlace: '',
      destination: '',
      startTime: '',
      startEndTime: [],
      showStartSelect: false,
      showEndSelect: false,
      showStartArea: false,
      showEndArea: false,
    };
  },
  watch: {
    searchInfo: {
      handler(newVal, oldVal) {
        for (let i in this.listQuery) {
          if (newVal[i]) this.listQuery[i] = newVal[i];
        }
        // 检查城市code
        if (this.listQuery.arrCityCode == '') {
          this.listQuery.arrCityCode = this.getCityCode(newVal.arriveCity);
        }

        if (newVal.depDate)
          this.listQuery.depDate = this.checkDepDate(newVal.depDate);
        if (newVal.departCity)
          this.listQuery.departCityInput = newVal.departCity;
        if (newVal.arriveCity)
          this.listQuery.arriveCityInput = newVal.arriveCity;
        this.listQuery.tripType = Number(this.listQuery.tripType);
        // if (
        //   !this.listQuery.depCityCode &&
        //   this.listQuery.arriveCity != '北京'
        // ) {
        //   this.listQuery.depCityCode = 'BJS';
        //   this.listQuery.departCity = '北京';
        //   this.listQuery.departCityInput = '北京';
        // }
        // if (!this.listQuery.arrCityCode) {
        //   this.listQuery.arrCityCode = 'SHA';
        //   this.listQuery.arriveCity = '上海';
        //   this.listQuery.arriveCityInput = '上海';
        // }
        // if (!this.listQuery.depDate) {
        //   this.listQuery.depDate = moment().add(1, 'days').format('YYYY-MM-DD');
        // }
        // console.log(367, this.listQuery);
        this.$store.commit('airTicket/setSearchInfo', { ...this.listQuery });
        // this.startEndTime = [newVal.depDate, newVal.arrDate];
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    //所有城市列表
    domesticCityList: {
      get() {
        let list: any[] = [];
        for (let i in domesticCity.default) {
          list.push(...domesticCity.default[i]);
        }
        // console.log('80-所有城市', list);
        // console.log(552, result);
        return list;
      },
    },
    materialList: {
      get() {
        let result: any = this.list;
        result.map((item: any) => {
          item['exampleList'] = item.example ? [item.example] : [];
        });
        return result;
      },
    },
  },
  created() {
    // 设置默认值
    // 设置默认出发地-目的地
    // if (!this.listQuery.depCityCode) {
    //   this.listQuery.depCityCode = 'BJS';
    //   this.listQuery.departCity = '北京';
    //   this.listQuery.departCityInput = '北京';
    // }
    // if (!this.listQuery.arrCityCode) {
    //   this.listQuery.arrCityCode = 'SHA';
    //   this.listQuery.arriveCity = '上海';
    //   this.listQuery.arriveCityInput = '上海';
    // }
    // this.listQuery.cabinClass = 'ALL_CABIN';
    // if (!this.listQuery.depDate) {
    //   // 默认为明天的日期
    //   // this.listQuery.depDate = moment().format('YYYY-MM-DD');
    //   this.listQuery.depDate = moment().add(1, 'days').format('YYYY-MM-DD');
    // }
  },
  mounted() {
    document.addEventListener('click', this.handlerClick);
    document.addEventListener('click', this.handlerEndClick);
    let startInput = (document as any).getElementById('startInput');
    startInput.addEventListener('focus', this.startInputContentSelected);
    startInput.addEventListener('blur', this.startInputBlur);

    let endInput = (document as any).getElementById('endInput');
    endInput.addEventListener('focus', this.endInputContentSelected);
    endInput.addEventListener('blur', this.endInputBlur);
  },
  destroyed() {
    document.removeEventListener('click', this.handlerClick);
    document.removeEventListener('click', this.handlerEndClick);
    (document as any)
      .getElementById('startInput')
      .removeEventListener('focus', this.startInputContentSelected);

    (document as any)
      .getElementById('endInput')
      .removeEventListener('focus', this.endInputContentSelected);
  },
  methods: {
    baiduMapReady({ BMap, map }: Record<string, any>) {
      let _this = this;
      new (BMap as any).LocalCity().get(function (r: any) {
        //定位城市
        if (r.name && !_this.listQuery.depCityCode) {
          let result = _this.getCityCode(r.name);

          _this.listQuery.depCityCode = result || '';
          _this.listQuery.departCity = r.name;
          _this.listQuery.departCityInput = r.name;
        }
        if (!_this.listQuery.depCityCode) {
          _this.searchInfo.depCityCode = 'BJS';
          _this.searchInfo.departCity = '北京';
          _this.searchInfo.departCityInput = '北京';
        }
      });
    },
    // 根据城市名获取城市code
    getCityCode(str: string) {
      let result = this.domesticCityList.find(
        (item: any) =>
          item.name == str || item.name.includes(str) || str.includes(item.name)
      );

      return result ? result.code : '';
    },
    //
    /**
     * 检查出发日期(如果出发日期小于今天,则改为明天)
     * @param {any} str:string
     * @returns {any} 日期 格式:2023-08-23
     */
    checkDepDate(str: string) {
      let result: any = str;
      // console.log('机票索检查日期:', str);
      if (str) {
        let s: any = Date.parse(new Date() as any); // Math.divide(Date.parse(new Date()), 1000);
        let v = Date.parse(str); // Math.divide(Date.parse(str), 1000);
        if (s > v) result = moment().add(1, 'days');
      } else {
        result = moment().add(1, 'days');
      }
      return moment(result).format('YYYY-MM-DD');
    },
    startInputContentSelected(e: any) {
      this.startInputActive = true;
      this.startInputStatus = 1;
      (document as any).getElementById('startInput').select();
    },
    /**
     * 输入框失去焦点后, 检查输入的城市是否正确(查询到城市后必须点击搜索结果中的数据才算有效,如果未点击则重置输入框内容)
     * @returns {any}
     */
    startInputBlur() {
      setTimeout(() => {
        this.startInputActive = false;
        this.startInputStatus = 2;
        // 如果输入了内容但没有从搜索结果中点击一个城市,则重置输入的内容为之前的城市
        if (this.listQuery.departCity !== this.listQuery.departCityInput) {
          this.listQuery.departCityInput = this.listQuery.departCity;
        }
      }, 200);
    },
    endInputBlur() {
      setTimeout(() => {
        this.endInputActive = false;
        this.endInputStatus = 2;
        if (this.listQuery.arriveCity !== this.listQuery.arriveCityInput) {
          this.listQuery.arriveCityInput = this.listQuery.arriveCity;
        }
      }, 200);
    },
    /**
     * 选中input内容
     * @param {any} e:any
     * @returns {any}
     */
    endInputContentSelected(e: any) {
      this.endInputActive = true;
      this.endInputStatus = 1;
      (document as any).getElementById('endInput').select();
    },
    /**
     * 说明：
     * 单程切换往返: 如果单程有"出发日期",则多程的"出发日期"就使用这个日期, 否则使用明天的日期, 返回日期=出发日期+3天
     * 往返切换单程: 如果往返有"出发日期", 则单程的"出发日期"就使用这个日期, 否则使用明天的日期
     * @param {any} e:number
     * @returns {any}
     */
    journeyTypeChange(e: number) {
      if (e === 1) {
        if (this.startEndTime && this.startEndTime[0]) {
          this.listQuery.depDate = this.startEndTime[0];
        } else {
          this.listQuery.depDate = moment().add(1, 'days').format('YYYY-MM-DD');
        }

        this.listQuery.arrDate = '';
      }
      if (e === 2) {
        if (this.listQuery.depDate) {
          this.startEndTime = [
            this.listQuery.depDate,
            moment(this.listQuery.depDate).add(3, 'days').format('YYYY-MM-DD'),
          ];
        } else {
          this.startEndTime = [
            moment().add(1, 'days').format('YYYY-MM-DD'),
            moment().add(3, 'days').format('YYYY-MM-DD'),
          ];
        }

        this.listQuery.depDate = this.startEndTime[0];
        this.listQuery.arrDate = this.startEndTime[1];
      }
      this.handleConfirm();
    },
    handleToggle() {
      this.listQuery.tripType = 2;
      if (this.listQuery.depDate) {
        this.startEndTime = [
          this.listQuery.depDate,
          moment(this.listQuery.depDate).add(3, 'days').format('YYYY-MM-DD'),
        ];
      } else {
        this.startEndTime = [
          moment().add(1, 'days').format('YYYY-MM-DD'),
          moment().add(3, 'days').format('YYYY-MM-DD'),
        ];
      }

      this.listQuery.depDate = this.startEndTime[0];
      this.listQuery.arrDate = this.startEndTime[1];
      this.handleConfirm();
    },
    handlerClick(event: any) {
      let isSelf = (document as any)
        .getElementById('startInput')
        .contains(event.target); // 这个是自己的区域
      let isSelfContent = (document as any)
        .getElementById('startInputContent')
        .contains(event.target);
      if (!isSelf && !isSelfContent) {
        // console.log('没在区域里面-->>>');
        this.showStartArea = false;
      }
      //
      let startSelectBox = (document as any)
        .getElementById('startSelectBox')
        .contains(event.target);
      if (!isSelf && !startSelectBox) {
        // console.log('没在区域里面-->>>');
        this.showStartSelect = false;
      }

      let endSelectBox = (document as any)
        .getElementById('endSelectBox')
        .contains(event.target);
      if (!isSelf && !endSelectBox) {
        // console.log('没在区域里面-->>>');
        this.showEndSelect = false;
      }
    },
    handlerEndClick(event: any) {
      let isSelf = (document as any)
        .getElementById('endInput')
        .contains(event.target); // 这个是自己的区域
      let isSelfContent = (document as any)
        .getElementById('endInputContent')
        .contains(event.target);
      if (!isSelf && !isSelfContent) {
        // console.log('没在区域里面-->>>');
        this.showEndArea = false;
      } else {
        console.log('在区域里--->>>>>');
      }
    },
    handleStartInput(e: any) {
      if (this.listQuery.departCityInput) {
        this.showStartArea = false;
        this.showStartSelect = true;
      } else {
        this.showStartArea = true;
        this.showStartSelect = false;
      }
    },
    handleStartClick() {
      this.showStartArea = true;
    },
    handleEndInput(e: any) {
      if (this.listQuery.arriveCityInput) {
        this.showEndArea = false;
        this.showEndSelect = true;
      } else {
        this.showEndArea = true;
        this.showEndSelect = false;
      }
    },
    handleEndClick() {
      this.showEndArea = true;
    },
    /**
     * 点击"城市"
     * 1. 点击全部城市列表中的一个城市
     * 2. 点击搜索结果中的一个城市
     * @param {any} e:any
     * @returns {any}
     */
    startOnChange(e: any) {
      // this.startPlace = `${e.name}(${e.code})`;
      this.listQuery.departCity = e.name;
      this.listQuery.departCityInput = e.name;
      this.listQuery.depCityCode = e.code;
      this.handleConfirm();
    },
    /**
     * 点击"城市"
     * 1. 点击全部城市列表中的一个城市
     * 2. 点击搜索结果中的一个城市
     * @param {any} e:any
     * @returns {any}
     */
    endOnChange(e: any) {
      // this.destination = `${e.name}(${e.code})`;
      this.listQuery.arriveCity = e.name;
      this.listQuery.arriveCityInput = e.name;
      this.listQuery.arrCityCode = e.code;
      this.handleConfirm();
    },
    /**
     * 互换出发地与目的地
     * @returns {any}
     */
    exchangeAddress() {
      let res = JSON.parse(JSON.stringify(this.listQuery));
      this.listQuery.departCity = res.arriveCity;
      this.listQuery.departCityInput = res.arriveCity;
      this.listQuery.depCityCode = res.arrCityCode;
      this.listQuery.arriveCity = res.departCity;
      this.listQuery.arriveCityInput = res.departCity;
      this.listQuery.arrCityCode = res.depCityCode;
      res = null;
    },
    startEndTimeChange(e: any) {
      if (e.length > 0) {
        this.listQuery.depDate = e[0];
        this.listQuery.arrDate = e[1];
        this.handleConfirm();
      }
    },
    // 单程-出发日期
    singleDateChange(e: any) {
      this.listQuery.depDate = e;
      this.listQuery.arrDate = '';
      this.handleConfirm();
      // console.log(this.$route.query);
      // let params = { ...this.$route.query };
      // params.depDate = e;
      // console.log(503, { ...this.listQuery })
      // window.
      // history.replaceState({ ...this.listQuery }, '', '/airTicket/list');
    },
    cabinClassChange(e: string) {
      this.handleConfirm();
    },
    /**
     * 筛选条件变更后,发送通知
     * @returns {any}
     */
    handleConfirm() {
      // this.$store.commit('airTicket/setSearchInfo', { ...this.listQuery });
      // this.$emit('on-change', this.listQuery);
    },
    handleSearch() {
      if (!this.listQuery.departCity) {
        message.call(this, {
          delay: 3000,
          title: '请选择出发地',
          type: 'error',
        });
        return;
      }
      if (!this.listQuery.arriveCity) {
        message.call(this, {
          delay: 3000,
          title: '请选择目的地',
          type: 'error',
        });
        return;
      }
      // console.log('出发日期', this.listQuery.depDate);
      // console.log('往返日期', this.startEndTime);
      this.$store.commit('airTicket/setSearchInfo', { ...this.listQuery });
      let result = { ...this.listQuery };
      delete result.departCityInput;
      delete result.arriveCityInput;
      // return false;

      if (this.$route.path == '/airTicket/list') {
        this.$emit('on-change', this.listQuery);
      } else {
        this.$router.push({
          path: '/airTicket/list',
          query: {
            ...result,
          },
        });
      }

      //
    },
  },
});
