
















































































































import Vue from 'vue';
// import _ from 'lodash';
import FairTag from './fairTag.vue';
import { $cookies } from '~/util/api';
import { ResponseCode } from '~/util/constant';
import { getXOssProcess } from '~/util/common';

export default Vue.extend({
  name: 'GoodsItem',
  components: { FairTag },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isHot: {
      type: Boolean,
      default: false,
    },
    smallTag: {
      type: Boolean,
      default: false,
    },
    industryId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
  created() {
    this.isFocus = this.data.isFocus;
    // console.log(this.data);
  },
  methods: {
    getXOssProcess,
    handleToDetail(str: string) {
      // this.$router.push({
      //   path: str,
      // });
      // let a = document.createElement('a');
      // a.href = str;
      // a.target = '_blank';
      // a.click();
      let routeData: any = this.$router.resolve(str);
      window.open(routeData.href, '_blank');
    },
    shouldLogin() {
      const access_token = $cookies.get('access_token');
      // 获取不到token为未登录, 前往登录页
      if (!access_token) {
        this.$login.show();
        // this.$router.push(`/login?redirect=${encodeURIComponent(this.$route.fullPath)}`);
      }
      return !!access_token;
    },
    async handleFocus(id: string) {
      await this.$store.commit(
        'user/setOrigins',
        location.origin + this.$route.fullPath
      );
      await this.$store.commit('user/setAction', 4);
      await this.$store.commit('user/setIndustryId', this.industryId);
      if (this.shouldLogin()) {
        const res: any = await this.$axios.post(
          `/api/exhibition-follow/follow`,
          { exhibitionId: id }
        );
        // const res = await this.$axios.get(`/api/user/exhibition/${id}`);
        if ((res as any).code === ResponseCode.SUCCESS) {
          this.isFocus = !this.isFocus;
        }
      }
    },
  },
});
