

























import Vue from 'vue';
import { ticket } from '@/pages/fair/ticket_free';
import { blackList } from '~/util/blackFair';

export default Vue.extend({
  name: 'FairTag',
  props: {
    exhibitionId: {
      type: [String, Number],
      default: '',
    },
    isNoTicket: {
      type: Number,
      default: 0,
    },
    smallTag: {
      type: Boolean,
      default: false,
    },
    tagNum: {
      type: Number,
      default: 3,
    },
    theme: {
      type: String,
      default: 'white',
    },
    thirdUrl: {
      type: String,
      default: null,
    },
    industryId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      blackList,
      tag: {
        list: [
          this.$t('exhibition.booth'),
          this.$t('exhibition.ticket'),
          this.$t('exhibition.exhibitor'),
        ],
        smallList: ['展位', '门票', '会刊'],
      },
    };
  },
  methods: {
    async handleToPage(tag: string) {
      const exhibitionId = this.exhibitionId;
      await this.$store.commit(
        'user/setOrigins',
        location.origin + this.$route.fullPath
      );
      await this.$store.commit('user/setIndustryId', this.industryId);

      switch (tag) {
        case '展位咨询':
        case '展位':
          await this.$store.commit('user/setAction', 1);
          window.open(`/exhibition/apply/${exhibitionId}.html`, '_self');
          break;
        case '门票预订':
        case '门票':
          // if (this.isNoTicket === 1) {
          //   this.$message.warning('门票已售罄!');
          // } else {
          //   window.open(`/exhibition/ticket/${exhibitionId}.html`, '_blank');
          // }
          await this.$store.commit('user/setAction', 2);
          if (this.thirdUrl) {
            const token = this.$cookies.get('access_token');
            if (!token) {
              await this.$store.commit(
                'user/setOrigins',
                location.origin + this.$route.fullPath
              );

              this.$login.show();
            } else {
              window.open(this.thirdUrl, '_blank');
            }
          } else {
            window.open(`/exhibition/ticket/${exhibitionId}.html`, '_blank');
          }
          break;
        case '展商/电子会刊查看':
        case '会刊':
          await this.$store.commit('user/setAction', 3);
          window.open(`/periodical/${exhibitionId}.html`, '_blank');
          break;
      }
    },
  },
});
