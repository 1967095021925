














































































import Vue from 'vue';
// @ts-ignore
import { sleep } from '~/util/common';
// @ts-ignore
import * as hotCity from './hotCity.json';
// @ts-ignore
import * as domesticCity from './flightCode.json';

export default Vue.extend({
  name: 'AreaPopup',
  components: {},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data(): any {
    return {
      activeName: 'hot',
    };
  },
  computed: {
    hotCityList: {
      get() {
        return hotCity.default;
      },
    },
    domesticCityList: {
      get() {
        let list: any[] = [];
        for (let i in domesticCity.default) {
          list.push({
            name: i,
            list: domesticCity.default[i],
          });
        }
        let compareByString = (a: any, b: any) => {
          return a.name.localeCompare(b.name);
        };
        // console.log(551, list);
        // let result = list.sort(this.compareByString);
        // console.log(552, result);
        return list.sort(compareByString);
      },
    },
  },
  mounted() {
    // console.log('544-城市组件', domesticCity, domesticCity.default);
    // console.log(this.domesticCityList);
  },
  methods: {
    compareByString(a: any, b: any): any {
      return a.name.localeCompare(b.name);
    },
    handleTabsClick(tab: any, event: any) {
      console.log(127, tab, event);
    },
    handleClick(e: any) {
      this.$emit('on-change', e);
      this.$emit('input', false);
    },
  },
});
