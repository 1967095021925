





































































































import Vue from 'vue';
import FairTag from './fairTag.vue';
import { getXOssProcess } from '~/util/common';

export default Vue.extend({
  name: 'Goods',
  methods: {
    getXOssProcess,
    handleDetail() {
      // this.$router.push({
      //   path: `/exhibition/${this.data.exhibitionId || this.data.id}.html`
      // })
      let text = this.$router.resolve({
        path: `/exhibition/${this.data.exhibitionId || this.data.id}.html`,
      });
      window.open(text.href, '_blank');
    },
  },
  components: { FairTag },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isHot: {
      type: Boolean,
      default: false,
    },
    smallTag: {
      type: Boolean,
      default: false,
    },
  },
});
